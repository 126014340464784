import { useState } from 'react';
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import { AppBootstrap } from 'app/AppBootstrap';
import { HomePage, WaitPage } from 'features';
import 'app/App.less';

export const App = () => {
	const [currentPage, setCurrentPage] = useState<'home' | 'wait'>('home');

	const [selectedBereich, setSelectedBereich] = useState<string>();

	const handleContinue = () => {
		setCurrentPage('wait');
	};

	return (
		<RecoilRoot>
			<RecoilNexus />
			<AppBootstrap>
				<ConfigProvider locale={deDE}>
					{currentPage === 'home' && (
						<HomePage
							setSelectedBereich={setSelectedBereich}
							onContinue={handleContinue}
						/>
					)}
					{currentPage === 'wait' && <WaitPage selectedBereich={selectedBereich} />}
				</ConfigProvider>
			</AppBootstrap>
		</RecoilRoot>
	);
};
