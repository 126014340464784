import { setRecoil } from 'recoil-nexus';

import { apiConfig } from 'api/apiConfig';
import { apiResMsgHandler } from 'api/apiResMsgHandler';
import { isFetchingState } from 'app';

export enum RequestMethod {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Delete = 'DELETE',
	Options = 'OPTIONS',
	Head = 'HEAD',
	Patch = 'PATCH'
}

export interface RequestConfig {
	method?: RequestMethod;
	endpoint: string;
	headers?: HeadersInit;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	body?: any;
	suppressAuth?: boolean;
	suppressErrorHandler?: boolean;
	suppressErrorHandlerForStatusCodes?: number[];
	suppressSuccessHandler?: boolean;
	suppressLoadingIndicator?: boolean;
	useJsfRestApi?: boolean;
}

export interface ResponseType {
	statusCode?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any;
	'content-disposition'?: string;
	isError?: boolean;
	errorMessage?: string;
	successMessage?: string;
}

const get = async (config: RequestConfig): Promise<ResponseType> => {
	config = { ...config, method: RequestMethod.Get };
	return request(config);
};

const post = async (config: RequestConfig): Promise<ResponseType> => {
	config = { ...config, method: RequestMethod.Post };
	return request(config);
};

const put = async (config: RequestConfig): Promise<ResponseType> => {
	config = { ...config, method: RequestMethod.Put };
	return request(config);
};

const del = async (config: RequestConfig): Promise<ResponseType> => {
	config = { ...config, method: RequestMethod.Delete };
	return request(config);
};

const request = async (config: RequestConfig): Promise<ResponseType> => {
	if (!config.suppressLoadingIndicator) setRecoil(isFetchingState, true);
	const retObj: ResponseType = {};
	const _config = { ...config };
	!config.method && (_config.method = RequestMethod.Get);

	// Prepend API base URL to endpoint if it does not already contain a valid base URL.
	if (!/^((http|https|ftp):\/\/)/i.test(_config.endpoint)) {
		const baseUrl = apiConfig.BACKEND_REST_URL;
		if (baseUrl) {
			_config.endpoint = `${baseUrl}${_config.endpoint}`;
		}
	}

	// Define headers if they are undefined
	if (_config.headers === undefined) {
		_config.headers = {};
	}
	// @ts-ignore
	!_config.headers['Accept'] && (_config.headers['Accept'] = 'application/json');
	// @ts-ignore
	!_config.headers['Content-Type'] && (_config.headers['Content-Type'] = 'application/json');
	if (!_config.suppressAuth) {
		// TODO if needed
	}

	try {
		// Do the actual request
		const response = await fetch(_config.endpoint, {
			method: _config.method,
			headers: _config.headers,
			body: _config.body ? JSON.stringify(_config.body) : undefined
		});

		retObj.statusCode = response.status;

		// Try to extract data
		let data;
		const responseContentType = response.headers.get('content-type');
		if (responseContentType?.startsWith('application/json')) {
			try {
				// First extract as plain text in case server sent wrong content-type
				data = await response.text();

				// And now try to parse json
				if (data) {
					try {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						data = JSON.parse(data);
					} catch (error) {
						//
					}
				}
			} catch (error) {
				//
			}
		} else if (responseContentType === 'application/octet-stream') {
			try {
				data = await response.blob();
				const contentDisposition = response.headers.get('content-disposition');
				contentDisposition && (retObj['content-disposition'] = contentDisposition);
			} catch (error) {
				//
			}
		} else {
			try {
				data = await response.text();
			} catch (error) {
				//
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		retObj.data = data;

		// handle response (logger and messages)
		if (response.ok) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			retObj.successMessage = apiResMsgHandler.handleSuccess({
				requestMethod: _config.method as RequestMethod,
				suppressSuccessHandler: _config.suppressSuccessHandler
			});
		} else {
			retObj.isError = true;
			retObj.errorMessage = apiResMsgHandler.handleErrors({
				data: config,
				statusCode: response.status,
				suppressErrorHandler: _config.suppressErrorHandler,
				suppressErrorHandlerForStatusCodes: _config.suppressErrorHandlerForStatusCodes
			});
		}
	} catch (error) {
		retObj.isError = true;
		retObj.errorMessage = apiResMsgHandler.handleErrors({
			data: config,
			suppressErrorHandler: _config.suppressErrorHandler,
			suppressErrorHandlerForStatusCodes: _config.suppressErrorHandlerForStatusCodes
		});
	}

	if (!config.suppressLoadingIndicator) setRecoil(isFetchingState, false);

	return retObj;
};

export const httpUtil = {
	RequestMethod,
	get,
	post,
	put,
	del,
	request
};
