import { useTranslation } from 'react-i18next';

import { appConfig } from 'app';
import styles from './footer.module.less';

export const Footer = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<a className={`${styles.text} ${styles.linkText}`} href={appConfig.URL_IMPRESSUM}>
				<span>{t('components:pageTemplate.footer.impressum')}</span>
			</a>
			<a className={`${styles.text} ${styles.linkText}`} href={appConfig.URL_DATENSCHUTZ}>
				<span>{t('components:pageTemplate.footer.datenschutz')}</span>
			</a>
			<a className={styles.text}>
				<span>© 2023 Breuninger</span>
			</a>
		</div>
	);
};
