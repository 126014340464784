import { stringUtils } from './stringUtils';

interface IAppQueryParams {
	locationId: string | null;
	smartstationId: string | null;
	bereich?: string;
}

const getParams = (): IAppQueryParams => {
	const params = new URLSearchParams(window.location.search);
	const locationIdParam = params.get('locationId');
	const smartstationIdParam = params.get('smartstationId');
	const bereichParam = params.get('bereich');

	return {
		locationId: locationIdParam,
		smartstationId: smartstationIdParam,
		bereich:
			bereichParam !== null && stringUtils.isNotEmpty(bereichParam) ? bereichParam : undefined
	};
};

export const appQueryParamsUtils = {
	getParams
};
