import styles from './subtitle.module.less';

interface IProps {
	text: string;
}

export const Subtitle = (props: IProps) => {
	return (
		<div className={styles.container}>
			<span className={styles.text}>{props.text}</span>
		</div>
	);
};
