import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nConfig } from 'i18n/i18nConfig';

const initialize = async (locale: keyof typeof i18nConfig.supportedLocales) => {
	const localeImport = (await i18nConfig.supportedLocales[
		locale
	].translationFileLoader()) as Resource;

	return new Promise<void>((resolve, reject) => {
		void i18next.use(initReactI18next).init(
			{
				lng: locale,
				fallbackLng: i18nConfig.fallback,
				resources: {
					[locale]: localeImport
				},
				interpolation: {
					escapeValue: false
				}
			},
			(error) => {
				if (error) return reject(error);

				t = (key: string, options?: object) => {
					return i18next.t(key, options);
				};

				resolve();
			}
		);
	});
};

let t = (key: string, options?: object) => {
	return i18next.t(key, options);
};

export const i18nextUtil = {
	initialize,
	t
};
