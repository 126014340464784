import { notification } from 'antd';

import { RequestMethod } from 'api/httpUtil';
import { logService } from 'api/loggerService';
import { i18n } from 'i18n';

interface IHandleErrors {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	statusCode?: number;
	suppressErrorHandler?: boolean;
	suppressErrorHandlerForStatusCodes?: number[];
}

/**
 * Log and shows common http status error depending on props configuration
 * @returns error message
 */
const handleErrors = (props: IHandleErrors): string => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { data, statusCode, suppressErrorHandler, suppressErrorHandlerForStatusCodes } = props;

	console.log(props);

	let isSuppress = false;
	if (suppressErrorHandler) {
		isSuppress = true;
	} else if (
		suppressErrorHandlerForStatusCodes &&
		statusCode &&
		suppressErrorHandlerForStatusCodes.includes(statusCode)
	) {
		isSuppress = true;
	}

	let errorMessage = null;
	if (statusCode && [403, 404, 406, 409].includes(statusCode)) {
		errorMessage = i18n.t(`error: ${statusCode}`);
	} else {
		errorMessage = `${i18n.t('error:general')} (RC: ${statusCode || 'unknown'} )`;
	}

	if (!isSuppress) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		logService.logError(`${statusCode || 'unknown error'} ${JSON.stringify(data)}`);

		// eslint-disable-next-line
		notification.error({
			message: i18n.t('error:error'),
			description: errorMessage,
			duration: 10
		});
	}

	return errorMessage;
};

interface IHandleSuccess {
	requestMethod: RequestMethod;
	suppressSuccessHandler?: boolean;
}

const handleSuccess = (props: IHandleSuccess): string | undefined => {
	const { requestMethod, suppressSuccessHandler } = props;
	if (!requestMethod) return;

	let successMessage = '';
	if (requestMethod === RequestMethod.Post) {
		successMessage = i18n.t('action:insert.success');
	} else if (requestMethod === RequestMethod.Put) {
		successMessage = i18n.t('action:edit.success');
	} else if (requestMethod === RequestMethod.Delete) {
		successMessage = i18n.t('action:delete.success');
	}

	if (!suppressSuccessHandler && successMessage) {
		// eslint-disable-next-line
		notification.success({
			message: successMessage,
			duration: 3
		});
	}
	return successMessage;
};

export const apiResMsgHandler = {
	handleErrors,
	handleSuccess
};
