import logo from 'images/breuninger-logo.svg';

import { appConfig } from 'app';
import styles from './header.module.less';

export const Header = () => {
	return (
		<div className={styles.container}>
			<a href={appConfig.URL_LOGO}>
				<img src={logo} alt="Breuninger logo" className={styles.logo} />
			</a>
		</div>
	);
};
