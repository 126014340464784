import { useTranslation } from 'react-i18next';

import { PageTemplate } from 'components/pageTemplate';
import { MeetingPoint } from 'components/pageTemplate/meetingPoint/MeetingPoint';
import styles from 'features/waitPage/waitPage.module.less';

interface IProps {
	selectedBereich: string | undefined;
}

export const WaitPage = (props: IProps) => {
	const { selectedBereich } = props;
	const { t } = useTranslation();

	return (
		<PageTemplate
			coverPhotoContent={
				<span className={styles.coverPhotoText}>
					≈ <br /> 10 Min
				</span>
			}
			title={t('features:waitPage.title')}
			subtitle={t('features:waitPage.subtitle')}
			content={
				<div className={styles.contentContainer}>
					{selectedBereich && <MeetingPoint bereich={selectedBereich} />}
				</div>
			}
		/>
	);
};
