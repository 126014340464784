import { httpUtil } from 'api/httpUtil';
import { appQueryParamsUtils } from 'utils';

export enum EMessageLevel {
	DEBUG = 'DEBUG',
	INFO = 'INFO',
	WARN = 'WARN',
	ERROR = 'ERROR',
	FATAL = 'FATAL'
}

export interface IDeviceInfo {
	deviceName: string;
	systemVersion: string;
	deviceType: string;
	deviceId: string;
}

const logInfo = (message: string) => {
	if (process.env.NODE_ENV === 'development') {
		console.info(message);
	}

	serviceLog(EMessageLevel.INFO, message);
};

const logError = (message: string) => {
	if (process.env.NODE_ENV === 'development') {
		console.error(message);
	}

	serviceLog(EMessageLevel.ERROR, message);
};

const getErrorMetadata = () => {
	const errorMetadata = {
		appQueryParams: appQueryParamsUtils.getParams(),
		userAgent: navigator.userAgent
	};

	return errorMetadata;
};

const serviceLog = (level: EMessageLevel, message: string) => {
	const logObject = {
		message,
		...getErrorMetadata()
	};

	httpUtil.post({
		endpoint: `v1/frontend-logger?level=${level}`,
		body: logObject,
		suppressAuth: true,
		suppressErrorHandler: true,
		suppressSuccessHandler: true,
		suppressLoadingIndicator: true
	});
};

export const logService = {
	log: serviceLog,
	logInfo,
	logError
};
