import styles from './coverPhoto.module.less';

interface IProps {
	content: React.ReactNode;
}

export const CoverPhoto = (props: IProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.circleContainer}>{props.content}</div>
		</div>
	);
};
