import { CoverPhoto } from 'components/pageTemplate/coverPhoto';
import { Footer } from 'components/pageTemplate/footer';
import { Header } from 'components/pageTemplate/header';
import { Subtitle } from 'components/pageTemplate/subtitle';
import { Title } from 'components/pageTemplate/title';
import styles from './pageTemplate.module.less';

interface IProps {
	coverPhotoContent: React.ReactNode;
	title: string;
	subtitle: string;
	content: React.ReactNode;
}

export const PageTemplate = (props: IProps) => {
	const { coverPhotoContent, title, subtitle, content } = props;

	return (
		<div className={styles.container}>
			<div className={styles.contentContainer}>
				<Header />
				<CoverPhoto content={coverPhotoContent} />
				<Title text={title} />
				<Subtitle text={subtitle} />
				{content}
			</div>

			<Footer />
		</div>
	);
};
