const isEmpty = (value?: string | null) => {
	return value === null || value === undefined || !value.trim().length;
};

const isNotEmpty = (value?: string | null) => {
	return !isEmpty(value);
};

export const stringUtils = {
	isEmpty,
	isNotEmpty
};
