import i18next from 'i18next';

import { i18nConfig } from 'i18n/i18nConfig';
import { i18nextUtil } from 'i18n/i18nextUtil';

const initialize = async (locale?: keyof typeof i18nConfig.supportedLocales) => {
	const _locale = locale ? locale : i18nConfig.fallback;

	await i18nextUtil.initialize(_locale);
};

export const i18n = {
	initialize,

	// i18next
	language: () => i18next.language,
	t: i18nextUtil.t
};
