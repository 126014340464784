const supportedLocales = {
	de: {
		name: 'Deutsch',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		translationFileLoader: () => require('i18n/translations/de.json')
	},
	en: {
		name: 'English',
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		translationFileLoader: () => require('i18n/translations/en.json')
	}
};
const fallback: keyof typeof supportedLocales = 'de';

export const i18nConfig = {
	fallback,
	supportedLocales
};
