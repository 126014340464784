import { useEffect, useState } from 'react';

import { i18n } from 'i18n';

interface IProps {
	children?: React.ReactNode;
}

export const AppBootstrap = (props: IProps) => {
	const [loading, setLoading] = useState(true);

	const initI18 = async () => {
		await i18n.initialize('de');
	};

	useEffect(() => {
		void (async function anyNameFunction() {
			setLoading(true);
			await initI18();
			setLoading(false);
		})();
	}, []);

	if (loading) {
		return <>...</>;
	}

	return <>{props.children}</>;
};
