const BEREICH_OPTIONS = [
	{ value: 'Damen', label: 'Damen' },
	{ value: 'Damen_Exquisit', label: 'Damen Exquisit' },
	{ value: 'Damen_Luxus', label: 'Damen Luxus' },
	{ value: 'Herren', label: 'Herren' },
	{ value: 'Herren_Exquisit', label: 'Herren Exquisit' },
	{ value: 'Herren_Luxus', label: 'Herren Luxus' },
	{ value: 'Sport', label: 'Sport' }
];

const BEREICH_MEETING_POINTS = [
	{ bereich: 'Damen', meetingPoint: 'ONE M CAFÉ AN DER GLASKUPPEL IM 1. OG' },
	{ bereich: 'Damen_Exquisit', meetingPoint: 'ONE M CAFÉ AN DER GLASKUPPEL IM 1. OG' },
	{ bereich: 'Damen_Luxus', meetingPoint: 'ONE M CAFÉ AN DER GLASKUPPEL IM 1. OG' },
	{ bereich: 'Herren', meetingPoint: "GENT'S BAR AN DER GLASKUPPEL IM 2. OG" },
	{ bereich: 'Herren_Exquisit', meetingPoint: "GENT'S BAR AN DER GLASKUPPEL IM 2. OG" },
	{ bereich: 'Herren_Luxus', meetingPoint: "GENT'S BAR AN DER GLASKUPPEL IM 2. OG" },
	{ bereich: 'Sport', meetingPoint: 'AUFZUG AN DER GLASKUPPEL IM 3. OG' }
];

export const appConfig = {
	BEREICH_OPTIONS,
	BEREICH_MEETING_POINTS,
	URL_LOGO: 'https://www.breuninger.com',
	URL_IMPRESSUM: 'https://www.breuninger.com/de/service/imprint/',
	URL_DATENSCHUTZ: 'https://www.breuninger.com/de/service/data-protection-regulation/#Log-Daten'
};
