import { useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useRecoilValue } from 'recoil';

import { httpUtil } from 'api/httpUtil';
import { appConfig, isFetchingState } from 'app';
import { PageTemplate } from 'components/pageTemplate';
import { MeetingPoint } from 'components/pageTemplate/meetingPoint/MeetingPoint';
import imgBell from 'images/bell.svg';
import { reactSelectStyles } from 'styles/reactSelectStyles';
import { appQueryParamsUtils, stringUtils } from 'utils';
import styles from './homePage.module.less';

interface IProps {
	setSelectedBereich: (value: string) => void;
	onContinue: () => void;
}

export const HomePage = (props: IProps) => {
	const { t } = useTranslation();
	const isSubmitting = useRecoilValue(isFetchingState);

	const appQuaryParams = appQueryParamsUtils.getParams();
	const { locationId, smartstationId, bereich } = appQuaryParams;

	const [selectedBereich, setSelectedBereich] = useState(
		bereich &&
			appConfig.BEREICH_OPTIONS.map((bereichOption) => bereichOption.value).includes(bereich)
			? bereich
			: appConfig.BEREICH_OPTIONS[0].value
	);

	const handleSubmit = async () => {
		const response = await httpUtil.post({
			endpoint: 'v1/mail/send',
			suppressSuccessHandler: true,
			body: {
				locationId,
				smartstationId,
				bereich: getBereichLabel(selectedBereich),
				meetingPoint: appConfig.BEREICH_MEETING_POINTS.find(
					(value) => value.bereich === selectedBereich
				)?.meetingPoint
			}
		});
		if (!response.isError) {
			props.setSelectedBereich(selectedBereich);
			props.onContinue();
		}
	};

	const submitDisabled =
		stringUtils.isEmpty(locationId) ||
		stringUtils.isEmpty(smartstationId) ||
		stringUtils.isEmpty(selectedBereich) ||
		isSubmitting;

	return (
		<PageTemplate
			coverPhotoContent={<img src={imgBell} alt="Bell" className={styles.imgBell} />}
			title={t('features:homePage.title')}
			subtitle={t('features:homePage.subtitle')}
			content={
				<div className={styles.contentContainer}>
					<span className={styles.listboxTitle}>
						{t('features:homePage.listboxTitle')}
					</span>

					<Select
						options={appConfig.BEREICH_OPTIONS}
						value={{
							value: selectedBereich,
							label: getBereichLabel(selectedBereich)
						}}
						isSearchable={false}
						styles={reactSelectStyles}
						// @ts-ignore
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						onChange={(newValue) => setSelectedBereich(newValue.value)}
					/>

					<MeetingPoint bereich={selectedBereich} />

					<button
						disabled={submitDisabled}
						className={styles.button}
						onClick={() => void handleSubmit()}
					>
						{isSubmitting ? (
							<Spin className={styles.submitSpin} />
						) : (
							<span className={styles.buttonText}>
								{t('features:homePage.buttonText')}
							</span>
						)}
					</button>
				</div>
			}
		/>
	);
};

const getBereichLabel = (bereichValue: string) => {
	return appConfig.BEREICH_OPTIONS.filter(
		(bereichOption) => bereichOption.value === bereichValue
	)[0].label;
};
