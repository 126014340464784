import { useTranslation } from 'react-i18next';
import { appConfig } from 'app';
import classes from 'components/pageTemplate/meetingPoint/MeetingPoint.module.less';

interface IProps {
	bereich: string;
}

const getMeetingPoint = (bereich: string) => {
	return appConfig.BEREICH_MEETING_POINTS.find((value) => value.bereich === bereich)
		?.meetingPoint;
};

export const MeetingPoint = (props: IProps) => {
	const { bereich } = props;
	const { t } = useTranslation();

	return (
		<div className={classes.main}>
			<div className={classes.label}>{t('components:pageTemplate:meetingPoint:label')}</div>
			<div className={classes.meetingPoint}>{getMeetingPoint(bereich)}</div>
		</div>
	);
};
