import { StylesConfig } from 'react-select';

export const reactSelectStyles: StylesConfig = {
	container: (provided, state) => ({
		...provided,
		width: '100%',
		height: '3.6rem',
		opacity: state.isDisabled ? 0.7 : undefined
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: '100%',
		height: '100%',
		backgroundColor: 'white',
		borderColor: '#a6a6a6',
		borderRadius: 0,
		boxShadow: state.isFocused ? '0 0 0 1px #38403e' : undefined,
		'&:hover': {
			borderColor: '#38403e'
		}
	}),
	input: (provided) => ({
		...provided,
		minHeight: '100%',
		height: '100%'
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '100%'
	}),
	valueContainer: (provided) => ({
		...provided,
		paddingTop: 0
	}),
	singleValue: (provided) => ({
		...provided,
		marginTop: '-0.4px',
		fontWeight: 400,
		fontSize: '1.5rem',
		lineHeight: '1.5rem',
		letterSpacing: '0.05rem'
	}),
	menu: (provided) => ({
		...provided,
		borderRadius: 0,
		zIndex: 100
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? '#38403e' : state.isFocused ? '#eaeaea' : undefined,
		fontSize: '1.5rem',
		'&:active': {
			backgroundColor: '#eaeaea'
		}
	}),
	placeholder: (base) => ({
		...base,
		color: 'red'
	})
};
